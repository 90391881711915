<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :disabled="isAddRecordActive"
          >
            <span>{{ $t('ADD_TEMPLATE') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <v-select
              v-model="templateCategory"
              :options="allCategories"
              label="name"
              :clearable="false"
              class="flex-grow-1 ml-50 mr-1"
              :placeholder="$t('SELECT_CATEGORY')"
              @input="fetchTemplates(templateCategory)"
            />
          </div>

        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refTemplateListTable"
      responsive
      :fields="templateTableColumns"
      :items="allTemplates.data"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: Name -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              variant="primary"
              :text="avatarText(data.item.name.substring(0,1))"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-muted" />
        </b-media>
      </template>
      <!-- Column:Reference ID -->
      <template #cell(ReferenceID)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.reference_id }}
          </span>
        </b-media>
      </template>
      <!-- Column:Language -->
      <template #cell(Language)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ resolveLanguage(data.item.language) }}
          </span>
        </b-media>
      </template>
      <!-- Column:Scope -->
      <template #cell(Scope)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.is_private === 1 ? 'PRIVATE' : 'PUBLIC' }}
          </span>
        </b-media>
      </template>
      <!-- Column:CreatedAt -->
      <template #cell(Type)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.type.toUpperCase() }}
          </span>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`game-row-${data.item.ID}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer  mr-1"
            size="16"
            @click="openMediaTemplate(data.item)"
          />
          <feather-icon
            :id="`game-row-${data.item.ID}-send-icon`"
            icon="Trash2Icon"
            class="cursor-pointer"
            size="16"
            @click="removeTemplate(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="allTemplates.paginator.count"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>
      </b-row>
    </div>
    <template-model
      :view-template="viewTemplate"
    />
  </b-card>
</template>

<script>
import {
  BCard, BLink,
  BRow, BCol, BButton, BTable,
  BMedia,
  BPagination, BAvatar, VBPopover,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref, computed } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import constants from '@/constants/static.json'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import TemplateModel from './TemplateModel.vue'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BPagination,
    BAvatar,
    BLink,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
    TemplateModel,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      isAddRecordActive: true,
      templateCategory: {
        ID: 0,
        name: 'Select Category',
        color: '',
        sv_text: '',
        en_text: '',
      },
      viewTemplate: {
        ID: 0,
        category_id: 0,
        name: '',
        sv_text: '',
        en_text: '',
        is_private: 0,
        team_size: 0,
        min_teams: 0,
        max_teams: 0,
      },
    }
  },
  created() {
    this.fetchTemplatesCategory()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('template', ['allCategories', 'allTemplates']),
  },
  setup() {
    // Table Handlers
    const templateTableColumns = [
      { key: 'Name', sortable: false },
      { key: 'ReferenceID', sortable: false },
      { key: 'Language', sortable: false },
      { key: 'Scope', sortable: false },
      { key: 'Type', sortable: false },
      { key: 'Actions' },
    ]

    const { PER_PAGE_OPTIONS, PER_PAGE } = constants
    const refTemplateListTable = ref(null)

    const perPage = ref(PER_PAGE)
    const totalTemplates = ref(0)
    const currentPage = ref(1)
    const perPageOptions = PER_PAGE_OPTIONS
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref('')

    const dataMeta = computed(() => {
      const localItemsCount = refTemplateListTable.value ? refTemplateListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalTemplates.value,
      }
    })

    const refetchData = () => {
      refTemplateListTable.value.refresh()
    }

    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      templateTableColumns,
      totalTemplates,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTemplateListTable,
      statusFilter,
      refetchData,
      avatarText,
      successMessage,
      showErrorMessage,
    }
  },
  methods: {
    ...mapActions('template', ['fetchAllCategories', 'fetchAllTemplates', 'deleteTemplate']),
    fetchTemplatesCategory() {
      this.fetchAllCategories().then(response => {
        if (response) {
          this.isAddRecordActive = true
        }
      }).catch(() => {
        this.showErrorMessage()
      })
    },
    resolveLanguage(data) {
      const language = constants.LANGUAGES.find(i => i.id === data)
      if (language) {
        return language.name
      }
      return ''
    },
    removeTemplate(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.name}  ${this.$i18n.t('TEMPLATE')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.onDeleteTemplate(data)
          }
        })
    },
    onDeleteTemplate(data) {
      if (data) {
        this.deleteTemplate(data).then(response => {
          if (response) {
            const params = `?category_id=${this.templateCategory.ID}`
            this.successMessage(this.$i18n.t('MESSAGE.TEMPLATE_DELETED'))
            this.fetchAllTemplates(params)
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
    openMediaTemplate(data) {
      if (data) {
        this.viewTemplate = { ...data }
        this.$bvModal.show('template-modal')
      }
    },
    fetchTemplates(e) {
      const params = `?category_id=${e.ID}`
      this.fetchAllTemplates(params).then(response => {
        if (response) {
          this.isAddRecordActive = true
        }
      }).catch(() => {
        this.showErrorMessage()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
