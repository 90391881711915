<template>
  <b-modal
    id="template-modal"
    ref="template-my-modal"
    title="Template"
    :ok-title="template.ID ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD')"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <b-form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('NAME')"
            label-for="id-name"
            :state="nameState"
            :invalid-feedback="$t('NAME_REQUIRED')"
          >
            <b-form-input
              id="id-name"
              v-model="template.name"
              maxlength="45"
              :state="nameState"
              :placeholder="$t('NAME')"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('REFERENCE_ID')"
            label-for="id-reference-number"
          >
            <b-form-input
              id="id-reference-number"
              v-model="template.reference_id"
              maxlength="45"
              :placeholder="$t('REFERENCE_ID')"
            />
          </b-form-group>
        </b-col>
        <!-- Scope name -->
        <b-col cols="6">
          <b-form-group
            :label="$t('SCOPE')"
            label-for="id-scope"
          >
            <v-select
              id="id-scope"
              v-model="scope"
              :placeholder="$t('SELECT_SCOPE')"
              :clearable="false"
              class="flex-grow-1"
              label="name"
              :options="scopeOptions"
            />
          </b-form-group>
        </b-col>
        <!-- Language -->
        <b-col cols="6">
          <b-form-group
            :label="$t('LANGUAGE')"
            label-for="id-language"
          >
            <v-select
              id="id-language"
              v-model="language"
              :options="languageOptions"
              label="name"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <!-- Description -->
        <b-col md="12">
          <b-form-group
            :label="$t('DESCRIPTION')"
            label-for="id-description"
          >
            <quill-editor
              id="id-description"
              v-model="template.description"
              maxlength="200"
              :options="editorOption"
              class="border-bottom-0"
            />
            <div
              id="quill-toolbar"
              class="d-flex justify-content-end border-top-0"
            >
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-align" />
              <button class="ql-link" />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal,
  VBModal, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    vSelect,
    quillEditor,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'viewTemplate',
  },
  props: {
    viewTemplate: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      template: {
        ID: 0,
        category_id: 0,
        reference_id: null,
        name: '',
        language: '',
        description: '',
        is_private: 0,
        team_size: 0,
        min_teams: 0,
        max_teams: 0,
      },
      nameState: null,
      scope: '',
      scopeOptions: constants.SCOPE,
      language: '',
      languageOptions: constants.LANGUAGES,
    }
  },
  watch: {
    viewTemplate: {
      handler() {
        if (this.viewTemplate) {
          this.scope = this.scopeOptions.find(i => i.id === this.viewTemplate.is_private)
          this.language = this.languageOptions.find(i => i.id === this.viewTemplate.language)
          this.template = this.viewTemplate
        }
      },
    },
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your template description',
    }

    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      editorOption,
    }
  },
  methods: {
    ...mapActions('template', ['updateTemplate', 'fetchAllTemplates']),
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    resetModal() {
      this.scope = ''
      this.nameState = null
      this.template = {}
    },
    discardExtraProperties() {
      delete this.template.template
      delete this.template.type
      delete this.template.record_status
      delete this.template.version
      delete this.template.created_by
      delete this.template.created_timestamp
      delete this.template.modified_by
      delete this.template.modified_timestamp
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      if (this.template.ID) {
        this.template.is_private = this.scope.id
        this.template.language = this.language.id
        this.discardExtraProperties()
        this.updateTemplate(this.template).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.TEMPLATE_UPDATED'))
            const params = `?category_id=${this.template.category_id}&type=game`
            this.fetchAllTemplates(params)
            this.$bvModal.hide('template-modal')
          }
        }).catch(error => {
          if (error.response.data.status === 'failed') {
            this.errorMessage(error.response.data.message.toString())
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
